<template>
    <div>
        <p>From: {{ label }} &lt;{{ mailbox }}@{{ domain }}&gt;</p>
        <p class="text-caption">{{ id }}</p>
    </div>
</template>
<style scoped>
p {
    margin-bottom: 0px;
}
</style>
<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        mailbox() {
            return this.attr.mailbox;
        },
        domain() {
            return this.attr.domain;
        },
        label() {
            return this.attr.label;
        },
    },

};
</script>
